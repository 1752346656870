import React from 'react';
import { nanoid } from 'nanoid';
import { useMutation } from 'react-query';
import { QBox, QButton, QCenter, QHeading, QMenuButton, QStack, QText, useToastProvider } from '@qualio/ui-components';

import TemplateMetadata from './TemplateMetadata/TemplateMetadata';
import { TemplateDetail } from '../../../../types';
import templateApi from '../../../../services/templateApi';
import { useNavigate } from 'react-router-dom';
import { formErrorMessageForToast } from '../../../../utils/backEndErrorMessages';
import { useFlags } from 'launchdarkly-react-client-sdk';

type TemplateDrawerTitleProps = {
  templateDetail: TemplateDetail | undefined;
  closeSlideoutPanel: any;
  setTemplateListLoadId: any;
};

const TemplateDrawerTitle: React.FC<TemplateDrawerTitleProps> = ({
  templateDetail,
  closeSlideoutPanel,
  setTemplateListLoadId,
}) => {
  const navigate = useNavigate();
  const goToEditTemplatePage = () => {
    navigate(`../doc-templates/${templateDetail?.id}/edit`, { replace: true });
  };

  const isArchived = templateDetail?.status === 'archived';

  const archiveRestoreText = isArchived ? 'Restore' : 'Archive';
  const successText = isArchived ? 'restored' : 'archived';

  const { showToast } = useToastProvider();
  const { useMtbeTemplates } = useFlags();

  const { mutate, isLoading } = useMutation(
    (td: TemplateDetail) =>
      templateApi.archiveRestoreTemplate(td.company_id, td.id.toString(), !isArchived, useMtbeTemplates),
    {
      onSuccess: () => {
        showToast({
          title: 'Success',
          description: 'Template successfully ' + successText,
          status: 'success',
        });
        setTemplateListLoadId(nanoid());
        closeSlideoutPanel();
      },
      onError: (error: any) => {
        showToast({
          title: 'Error',
          description: formErrorMessageForToast('Failed! Template prefix is already in use', error),
          status: 'error',
        });
      },
    },
  );

  const archiveRestoreTemplate = () => {
    if (isLoading || !templateDetail) {
      return;
    }

    mutate(templateDetail);
  };

  if (!templateDetail) {
    return null;
  }

  return (
    <QBox display="flex" flexDirection="row" justifyContent="space-between" w={'100%'}>
      <QStack direction={['row', 'column']} spacing="0">
        <QStack direction={['column', 'row']} spacing="80%">
          <QText>{templateDetail?.prefix.replace('-', '') || ''}</QText>
          <QBox> </QBox>
        </QStack>
        <QHeading as="h2" size="md">
          {templateDetail?.name || ''}
        </QHeading>
        <TemplateMetadata templateDetail={templateDetail} />
      </QStack>
      <QCenter>
        <QStack direction={['column', 'row']} spacing="4">
          <div data-cy="dropdownMenuMoreOption">
            <QMenuButton
              buttonLabel="options"
              itemSize="sm"
              items={[{ id: '0', label: archiveRestoreText }]}
              onItemClick={archiveRestoreTemplate}
              variant="icon"
            />
          </div>
          {templateDetail.status === 'effective' && (
            <QButton onClick={goToEditTemplatePage} variant="outline" cy-data="editButton">
              Edit
            </QButton>
          )}
        </QStack>
      </QCenter>
    </QBox>
  );
};

export default TemplateDrawerTitle;
