import {
  QBox,
  QStep,
  QStepper,
  QStepperProgress,
  QSteps,
  QStepVariantOptions,
  useToastProvider,
} from '@qualio/ui-components';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import DefaultSectionsContent from '../../components/CreateEditTemplate/DefaultSectionsContent/DefaultSectionsContent';
import CreateEditTemplateFooter from '../../components/CreateEditTemplate/Footer/CreateEditTemplateFooter';
import IDAndTitle from '../../components/CreateEditTemplate/PrefixAndTitle/IDAndTitle';
import Properties from '../../components/CreateEditTemplate/Properties/Properties';
import { SectionDTO, SectionErrors, SectionResolutionDTO, TemplateDTO, TemplateRequestBody } from '../../types';
import templateApi from '../../services/templateApi';
import { CreateEditTemplatePage } from './CreateEditTemplatePage';
import DefaultContent from '../../components/CreateEditTemplate/DefaultContent/DefaultContent';
import { formErrorMessageForToast } from '../../utils/backEndErrorMessages';
import SectionResolutionModal from '../../components/CreateEditTemplate/SectionResolutionModal/SectionResolutionModal';
import styles from './CreateEditTemplateView.module.css';
import { useFlags } from 'launchdarkly-react-client-sdk';

type CreateEditTemplateContentProps = {
  setCanShowDialogLeavingPage: Dispatch<SetStateAction<boolean>>;
  companyId: number;
  templateId: string | undefined;
  originalTemplateDto: TemplateDTO;
  templateDto: TemplateDTO;
  setTemplateDto: (templateDto: TemplateDTO) => void;
  originalSectionAmount: number;
  isLoaded: boolean;
};

const getDeletedSections = (originalSections: SectionDTO[], currentSections: SectionDTO[]): SectionDTO[] => {
  const keptSectionIds = currentSections.filter((section) => section.id).map((section) => section.id);
  return originalSections.filter((section) => section.id && !keptSectionIds.includes(section.id));
};

const CreateEditTemplateContent: React.FC<CreateEditTemplateContentProps> = ({
  setCanShowDialogLeavingPage,
  companyId,
  templateId,
  originalTemplateDto,
  templateDto,
  setTemplateDto,
  originalSectionAmount,
  isLoaded,
}) => {
  const { showToast } = useToastProvider();
  const { useMtbeTemplates } = useFlags();

  const initialPageErrorsCreate = [true, true, false, false];
  const initialPageErrorsEdit = [false, false, false, false];

  const getInitialPageErrors = () => {
    if (templateId) {
      return initialPageErrorsEdit;
    } else {
      return initialPageErrorsCreate;
    }
  };

  const [pageErrors, setPageErrors] = useState<boolean[]>(getInitialPageErrors());

  const setPageError = (currentPage: CreateEditTemplatePage, value: boolean) => {
    pageErrors[currentPage] = value;
    setPageErrors(pageErrors);
  };

  const validatePage = (pageToValidate: CreateEditTemplatePage) => {
    switch (pageToValidate) {
      case CreateEditTemplatePage.IDAndTitle:
        if (!titleTouched) {
          setTitleError('required');
        }
        break;
      case CreateEditTemplatePage.DefaultSections:
        const errors = [...sectionErrors];
        for (let i = 0; i < sectionTitlesTouched.length; i++) {
          if (!sectionTitlesTouched[i]) {
            errors[i].titleBlank = true;
          }
        }
        setSectionErrors(errors);
        break;
    }
  };

  let actionWord = 'create';
  if (templateId) {
    actionWord = 'update';
  }

  type StepVariant = Exclude<QStepVariantOptions, 'active'>;

  const initialStepsNewEditor: StepVariant[] = ['default', 'default', 'default', 'default', 'default'];
  const [page, setPage] = useState<CreateEditTemplatePage>(CreateEditTemplatePage.IDAndTitle);
  const [steps, setSteps] = useState<StepVariant[]>(initialStepsNewEditor);

  const [prefixError, setPrefixError] = useState<string>('');
  const [titleError, setTitleError] = useState<string>('');
  const [titleTouched, setTitleTouched] = useState<boolean>(!!templateId);
  const [sectionErrors, setSectionErrors] = useState<SectionErrors[]>([new SectionErrors()]);
  const [sectionTitlesTouched, setSectionTitlesTouched] = useState<boolean[]>([false]);

  useEffect(() => {
    if (templateId) {
      const sectionTitlesTouchedForEdit = new Array(originalSectionAmount);
      const sectionTitleBlank = [];
      for (let i = 0; i < sectionTitlesTouchedForEdit.length; i++) {
        sectionTitlesTouchedForEdit[i] = true;
        sectionTitleBlank.push(new SectionErrors());
      }
      setSectionTitlesTouched(sectionTitlesTouchedForEdit);
      setSectionErrors(sectionTitleBlank);
    }
  }, [templateId, originalSectionAmount]);

  const createOrUpdateTemplate = (templateRequestBody: TemplateRequestBody) => {
    if (templateId) {
      return templateApi.updateTemplate(companyId, templateId, templateRequestBody);
    } else {
      return templateApi.createTemplate(companyId, templateRequestBody, useMtbeTemplates);
    }
  };

  const navigate = useNavigate();
  const mutation = useMutation(
    (templateRequestBody: TemplateRequestBody) => createOrUpdateTemplate(templateRequestBody),
    {
      onSuccess: (response) => {
        setIsSubmitButtonDisabled(true);
        showToast({
          title: 'Success',
          description: 'Template successfully ' + actionWord + 'd',
          status: 'success',
        });

        navigate(`../doc-templates/${response.id}`, { replace: true });
      },
      onError: (error: any) => {
        if (error?.response?.data?.body === 'Template prefix is already in use') {
          setPage(CreateEditTemplatePage.IDAndTitle);
          setPrefixError('duplicate');
          setPageError(CreateEditTemplatePage.IDAndTitle, true);
        }
        if (error?.response?.data?.body === 'Template title is already in use') {
          setPage(CreateEditTemplatePage.IDAndTitle);
          setTitleError('duplicate');
          setPageError(CreateEditTemplatePage.IDAndTitle, true);
          document.getElementsByName('title')[0].focus();
        }
        if (error?.response?.data?.body === 'Invalid attachment id') {
          setPage(CreateEditTemplatePage.DefaultContentNewEditor);
          document.getElementById('sectionEditorContent')?.focus();
        }
        console.error('Failed to update template. Error from server was: ' + error?.response?.data?.body);

        showToast({
          title: 'Error',
          description: formErrorMessageForToast('Failed to ' + actionWord + ' the template', error),
          status: 'error',
        });
      },
    },
  );

  const showResolutionModal = useCallback(() => {
    setIsSectionResolutionModalOpen(true);
  }, []);

  const [deletedSections, setDeletedSections] = useState([] as SectionDTO[]);

  const submit = useCallback(
    (resolutions: SectionResolutionDTO[]) => {
      setCanShowDialogLeavingPage(false);
      mutation.mutate(new TemplateRequestBody(templateDto, companyId, resolutions));
    },
    [templateDto, companyId, mutation, setCanShowDialogLeavingPage],
  );

  const onSubmit = () => {
    if (!pageErrors[pageErrors.length - 1]) {
      const sectionsDeleted = getDeletedSections(originalTemplateDto.sections, templateDto.sections);
      setDeletedSections(sectionsDeleted);
      if (sectionsDeleted.length > 0) {
        showResolutionModal();
      } else {
        submit([]);
      }
    }
  };

  const changePage = (pageIndex: number) => {
    setPage(pageIndex);
  };

  const nextPage = () => {
    if (pageErrors[page]) {
      validatePage(page);
      return;
    }

    const newSteps = [...steps];
    newSteps[page] = 'visited';
    setSteps(newSteps);
    changePage(page + 1);
  };

  const previousPage = () => {
    const newSteps = [...steps];
    newSteps[page] = 'default';
    setSteps(newSteps);
    changePage(page - 1);
  };

  const doNothing = () => {
    return;
  };

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);

  const [isSectionResolutionModalOpen, setIsSectionResolutionModalOpen] = useState<boolean>(false);

  return (
    <>
      <QBox pt={6} className={`${styles['stepper-wrapper']}`}>
        <QStepper activeStep={page} onStepItemClicked={doNothing}>
          <QSteps>
            <QStepperProgress />
            <QStep title="ID and title" variant={steps[CreateEditTemplatePage.IDAndTitle]}>
              <IDAndTitle
                setTemplateDto={setTemplateDto}
                templateDto={templateDto}
                prefixError={prefixError}
                setPrefixError={setPrefixError}
                titleError={titleError}
                setTitleError={setTitleError}
                setPageError={setPageError}
                titleTouched={titleTouched}
                setTitleTouched={setTitleTouched}
                templateId={templateId}
              />
            </QStep>
            <QStep title="Default sections" variant={steps[CreateEditTemplatePage.DefaultSections]}>
              <DefaultSectionsContent
                setTemplateDto={setTemplateDto}
                templateDto={templateDto}
                setPageError={setPageError}
                sectionTitlesTouched={sectionTitlesTouched}
                setSectionTitlesTouched={setSectionTitlesTouched}
                sectionErrors={sectionErrors}
                setSectionErrors={setSectionErrors}
              />
            </QStep>
            <QStep title="Default content" variant={steps[CreateEditTemplatePage.DefaultContentNewEditor]}>
              <DefaultContent setTemplateDto={setTemplateDto} templateDto={templateDto} />
            </QStep>
            <QStep title="Properties" variant={steps[CreateEditTemplatePage.PropertiesNewEditor]}>
              <Properties setTemplateDto={setTemplateDto} templateDto={templateDto} setPageError={setPageError} />
            </QStep>
          </QSteps>
        </QStepper>
      </QBox>
      <CreateEditTemplateFooter
        page={page}
        nextPage={nextPage}
        onSubmit={onSubmit}
        previousPage={previousPage}
        submitInProgress={mutation.isLoading || isSubmitButtonDisabled}
        isLoaded={isLoaded}
        templateId={templateId}
      />
      {isSectionResolutionModalOpen && (
        <SectionResolutionModal
          isOpen={isSectionResolutionModalOpen}
          setIsModalOpen={setIsSectionResolutionModalOpen}
          submit={submit}
          deletedSections={deletedSections}
          sectionsToModify={templateDto.sections}
        />
      )}
    </>
  );
};

export default CreateEditTemplateContent;
