import React, { useCallback } from 'react';
import { QBox, QSelect, QSelectItem } from '@qualio/ui-components';
import { useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

const effectiveTemplatesOption = {
  label: 'Effective',
  value: false,
};
const archivedTemplatesOption = {
  label: 'Archived',
  value: true,
};
const statusFilterOptions: QSelectItem<string, boolean>[] = [effectiveTemplatesOption, archivedTemplatesOption];

const statusesFilterOptions: QSelectItem[] = [
  {
    label: 'Effective',
    value: 'effective',
  },
  {
    label: 'Archived',
    value: 'archived',
  },
];

type StatusFilterProps = {
  templateStatuses: string[];
  setTemplateStatuses: React.Dispatch<React.SetStateAction<string[]>>;
  archivedStatus: boolean;
  setArchivedStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StatusFilter: React.FC<StatusFilterProps> = ({
  templateStatuses,
  setTemplateStatuses,
  archivedStatus,
  setArchivedStatus,
}) => {
  const [, setSearchParams] = useSearchParams();
  const { useMtbeTemplates } = useFlags();

  const onStatusFilterChange = useCallback(
    (option: QSelectItem<string, boolean> | null) => {
      if (option !== null) {
        setArchivedStatus(option.value);
        setSearchParams((params: URLSearchParams) => {
          params.delete('pageIndex');
          return params;
        });
      }
    },
    [setArchivedStatus, setSearchParams],
  );

  const onStatusesFilterChange = useCallback(
    (option: QSelectItem | null) => {
      if (option !== null) {
        setTemplateStatuses([option.value]);
        setSearchParams((params: URLSearchParams) => {
          params.delete('pageIndex');
          return params;
        });
      }
    },
    [setTemplateStatuses, setSearchParams],
  );

  return (
    <QBox w="30%">
      {!useMtbeTemplates && (
        <QSelect
          size="sm"
          options={statusFilterOptions}
          onChange={onStatusFilterChange}
          value={archivedStatus}
          data-cy="template-status-filter"
          aria-label="Template status filter"
        />
      )}
      {useMtbeTemplates && (
        <QSelect
          size="sm"
          options={statusesFilterOptions}
          onChange={onStatusesFilterChange}
          value={templateStatuses[0]}
          data-cy="template-status-filter"
          aria-label="Template status filter"
        />
      )}
    </QBox>
  );
};
